import { useEffect } from "react";
import { io } from "socket.io-client";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useSocket = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
      // const socket = io("http://localhost:3000", {
      const socket = io("https://fctransfertool.com", {
      transports: ["websocket"], // Forzar WebSocket para menos latencia
      reconnection: true, // Habilitar reconexión automática
      withCredentials: true, // Enviar cookies/sesión si es necesario
    });
    
    socket.on("buyerAccountUpdated", (updatedBuyerAccount) => {
      console.log("📡 Buyer actualizado:", updatedBuyerAccount);
      
      const existingData = queryClient.getQueryData("buyerAccounts");
      console.log("🔄 Datos antes de actualizar en cache:", existingData);
    
      queryClient.setQueryData("buyerAccounts", (oldData: any) => {
        if (!oldData) return [updatedBuyerAccount];
    
        const newData = oldData.map((buyer: any) => {
          if (buyer.id === "Loading..." && buyer.buyerEmail === updatedBuyerAccount.buyerEmail) {
            console.log("🔥 Reemplazando el Buyer con datos reales:", updatedBuyerAccount);
            return updatedBuyerAccount;
          }
    
          return buyer.id === updatedBuyerAccount.id
            ? {
                ...buyer,
                buyerAccountStatus: updatedBuyerAccount.buyerAccountStatus ?? buyer.buyerAccountStatus,
                updatedAt: updatedBuyerAccount.updatedAt ?? buyer.updatedAt,
              }
            : buyer;
        });
    
        console.log("✅ Datos en cache DESPUÉS de actualizar:", newData);
        return newData;
      });
    
      console.log("🚀 Invalidando consulta para forzar refetch...");
      setTimeout(() => {
        console.log("⏱ Espera de 1 segundo antes del refetch...");
        queryClient.invalidateQueries("buyerAccounts")
      }, 1000)
      
    });
    
    
    socket.on("supplierAccountUpdated", (updatedSupplierAccount) => {
      console.log("📡 Supplier actualizado:", updatedSupplierAccount);
      // toast.success("¡Cuenta Supplier actualizada!", updatedSupplierAccount.supplierEmail);
    
      // 🔍 Verificar si hay datos en caché
      const existingData = queryClient.getQueryData("supplierAccounts");
      console.log("🔄 Datos antes de actualizar en cache:", existingData);
    
      queryClient.setQueryData("supplierAccounts", (oldData: any) => {
        if (!oldData) return [updatedSupplierAccount];
    
        const newData = oldData.map((supplier: any) => {
          if (supplier.id === "Loading..." && supplier.supplierEmail === updatedSupplierAccount.supplierEmail) {
            console.log("🔥 Reemplazando el Supplier con datos reales:", updatedSupplierAccount);
            return updatedSupplierAccount;
          }
    
          return supplier.id === updatedSupplierAccount.id
            ? {
                ...supplier,
                supplierAccountStatus: updatedSupplierAccount.supplierAccountStatus ?? supplier.supplierAccountStatus,
                updatedAt: updatedSupplierAccount.updatedAt ?? supplier.updatedAt,
              }
            : supplier;
        });
    
        console.log("✅ Datos en cache DESPUÉS de actualizar:", newData);
        return newData;
      });
    
      console.log("🚀 Invalidando consulta para forzar refetch...");
      setTimeout(() => {
        console.log("⏱ Espera de 1 segundo antes del refetch...");
        queryClient.invalidateQueries("supplierAccounts")
      }, 1000)
      
    });
    
    
    socket.on("transferSessionUpdated", (updatedSession) => {
      console.log("📡 TransferSession actualizada:", updatedSession);
    
      const existingData = queryClient.getQueryData("transferSessions");
      console.log("🔄 Datos antes de actualizar en cache:", existingData);
    
      queryClient.setQueryData("transferSessions", (oldData: any) => {
        if (!oldData) return [updatedSession];
    
        const newData = oldData.map((session: any) =>
          session.sessionId === updatedSession.sessionId
            ? {
                ...session,
                totalCoinsTransferred: updatedSession.totalCoinsTransferred,
                updatedAt: updatedSession.updatedAt,
              }
            : session
        );
    
        console.log("✅ Datos en cache DESPUÉS de actualizar:", newData);
        return newData;
      });
    
      queryClient.invalidateQueries("transferSessions");
    });
    

    return () => {
      socket.disconnect();
    };
  }, [queryClient]);

};

export default useSocket;
